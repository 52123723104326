import get from 'lodash.get';
import keys from 'lodash.keys';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

// Redux
import {
  toggleDetailsPopupWithId,
  bypassProductSelection
} from '../../../redux/reducers/assignLearningMaterial.reducer.js';
import { openRecallLicenceModal } from '../../../redux/actions/hubUi';
import { setProductFinderData } from '../../../redux/actions/productFinderActions';
import { initialiseForm } from '../../../redux/reducers/user/licenceRecall.reducer';
import { resetFiltersProfileCourseMaterials } from '../../../redux/actions/hubProfileCourseMaterialFilters';
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
// Constants
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import colors from '../../../globals/colors';

import { HubLayoutConstants, HubIllustrationConstants, productInformationContext } from '../../../globals/hubConstants';
import { isLtiMode } from '../../../utils/platform.js';

// HOC
import withHubList from '../withHubList';
import withLocalizedContent from '../../../language/withLocalizedContent';
import withDataRecency from '../../../dataRecency/withDataRecency';
import withBreakpoint from '../../../decorators/withBreakpoint';
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import withSearchInitialiser from '../../../components/SearchInitialiser/withSearchInitialiser';

// Components
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import LicenceListItem from '../../../components/ListItems/LicenceListItem/LicenceListItem';
import getOrgLicencesHeaderItems from '../../HubDashboardLayout/Services/getOrgLicencesHeaderItems';
import reduceLearningAssignmentsToProductDetails from '../../HubDashboardLayout/Services/reduceLearningAssignmentsToProductDetails';
import HubProfilePageMaterialFilter from '../../../components/HubProfilePageMaterialFilter/HubProfilePageMaterialFilter';
import userRoles, { roleIsAtLeast } from '../../../globals/userRoles.js';

class HubPersonProfileLicenses extends Component {
  static hasClassAssignments = (assignments, classId) => {
    if (assignments) {
      const assignmentIds = Object.keys(assignments);
      for (let i = 0; i < assignmentIds.length; i += 1) {
        if (
          assignments[assignmentIds[i]].learningAssignments &&
          assignments[assignmentIds[i]].learningAssignments.length
        ) {
          const learningAssignments = assignments[assignmentIds[i]].learningAssignments;
          for (let j = 0; j < learningAssignments.length; j += 1) {
            if (learningAssignments[j].groupDetails && learningAssignments[j].groupDetails.groupId === classId) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  static getAssignmentId = (products, productId) => {
    const assignment = products?.[productId]?.learningAssignments?.[0];
    return {
      learningAssignmentId: assignment?.learningAssignmentId || ''
    };
  };

  componentDidMount() {
    this.loadPersonLicenses();
  }

  componentDidUpdate(prevProps) {
    const { formState, licenceRecallFailed } = this.props;

    if (prevProps.formState !== formState && formState === 'CONFIRMATION' && !licenceRecallFailed) {
      this.loadPersonLicenses();
    }
  }

  getFilteredAssignmentIds = (products, learningAssignments) => {
    const { licencesContext, classroomId, orgId } = this.props;
    const {
      LICENCES_CONTEXT: { CLASS }
    } = HubLayoutConstants;

    const filteredAssignmentIds =
      licencesContext === CLASS
        ? keys(products).filter(id => {
            const test = get(products[id], ['learningAssignments'], [])
              .map(item => get(item, ['groupDetails']))
              .filter(group => group.groupId === classroomId && group.type === 'CLASS' && group.parentId === orgId);
            return test.length;
          })
        : keys(learningAssignments);

    return filteredAssignmentIds;
  };

  loadPersonLicenses = () => {
    const { personId, loadLicenses, currentOrganisationId, personUsertype } = this.props;
    loadLicenses('userAssignments', false, {
      orgId: currentOrganisationId,
      userId: personId,
      targetUsertype: personUsertype,
      active: true,
      expiring: true,
      expired: true,
      notStarted: true,
      noLicence: true
    });
  };

  render() {
    const {
      breakpoint,
      localizedContent: { hubGlossary: hubContent },
      loadingResults,
      userRole,
      userAssignments,
      toggleDetailsPopupWithIdAction,
      licencesContext = '',
      classname,
      orgId,
      classroomId,
      isTeacherAssignedToClass,
      assignMaterial,
      personFirstname,
      initialiseAssignLicence,
      personId,
      initialiseRecall,
      openRecallLicenceModalAction,
      isActive,
      productData,
      resetFiltersProfileCourseMaterialsAction,
      setProductFinderDataAction,
      people,
      currentOrganisationLti
    } = this.props;

    const {
      LICENCES_CONTEXT: { CLASS, ORG }
    } = HubLayoutConstants;

    const products = licencesContext === CLASS ? productData : userAssignments;

    const EnhancedHubListPage = withHubList(LicenceListItem);

    const dropdownActions = {
      toggleMaterialsToClassModal: id => {
        const selectedUsers = roleIsAtLeast(userRoles.TEACHER, people[personId]?.roleName)
          ? { teacherIdList: [personId] }
          : { studentIdList: [personId] };
        const { learningAssignmentId } = HubPersonProfileLicenses.getAssignmentId(products, id);

        setProductFinderDataAction({
          formState: productFinderFormStates.REVIEW_LICENCES,
          product: { ...products[id].productDetails, productid: id },
          assignLicences: true,
          assignLicenceToTeacher: true,
          existingAssignmentId: learningAssignmentId,
          selectedUsers,
          selectedProducts: [{ ...products[id].productDetails, productid: id }]
        });

        initialiseAssignLicence('USER_PROFILE', orgId, [personId], id);

        assignMaterial();
      },
      toggleDetails: toggleDetailsPopupWithIdAction,
      recallLicence: ({ learningAssignmentId = '', activationCode = '' }) => {
        openRecallLicenceModalAction();
        initialiseRecall(orgId, personId, learningAssignmentId, activationCode);
      }
    };

    const getEmptyStateInactiveSubtitle = context => {
      if (context === CLASS) return hubContent.class_invited_user_empty_state_subtitle;
      if (isLtiMode()) return hubContent.org_invited_user_empty_state_subtitle_ltihub;
      return hubContent.org_invited_user_empty_state_subtitle;
    };

    let emptyStateDataActiveSubtitle =
      licencesContext === CLASS
        ? hubContent.class_people_learning_tab_empty_state_subtitle
        : hubContent.org_students_learning_tab_empty_state_subtitle.replace('{firstName}', personFirstname);
    if (currentOrganisationLti) {
      emptyStateDataActiveSubtitle = '';
    }
    const emptyStateData = isActive
      ? {
          iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
          title: currentOrganisationLti
            ? ''
            : hubContent.org_students_learning_tab_empty_state_title.replace('{firstName}', personFirstname),
          subtitle: emptyStateDataActiveSubtitle,
          button: !currentOrganisationLti
            ? {
                icon: GLYPHS.ICON_PLUS,
                text: hubContent.add_learning_materials_button,
                action: assignMaterial
              }
            : null
        }
      : {
          iconSrc: HubIllustrationConstants.INVITED,
          title: (currentOrganisationLti
            ? hubContent.org_invited_user_empty_state_title_ltihub
            : hubContent.org_invited_user_empty_state_title
          ).replace('{firstName}', personFirstname),
          subtitle: getEmptyStateInactiveSubtitle(licencesContext),
          button: null
        };

    const learningAssignments = reduceLearningAssignmentsToProductDetails(products);

    const headerOptions = {
      name:
        licencesContext === CLASS
          ? `${classname}'s ${hubContent.course_materials.toLowerCase()}`
          : hubContent.org_licences_header_products
    };

    const filteredAssignmentIds = this.getFilteredAssignmentIds(products, learningAssignments);
    const totalResults = filteredAssignmentIds.length;

    let filtersEmptyStateButton = emptyStateData.button;
    const hasAssignmentsForClass =
      licencesContext === CLASS ? HubPersonProfileLicenses.hasClassAssignments(userAssignments, classroomId) : false;
    const shouldShowProductsForClassUser =
      licencesContext === CLASS &&
      filteredAssignmentIds.length &&
      ((productData && Object.keys(productData).length && hasAssignmentsForClass) || !hasAssignmentsForClass);

    if (hasAssignmentsForClass) {
      emptyStateData.title = hubContent.class_page_filters_empty_state_subtitle;
      emptyStateData.subtitle = hubContent.class_page_filters_empty_state_title;
      filtersEmptyStateButton = {
        text: hubContent.class_page_filters_reset,
        action: resetFiltersProfileCourseMaterialsAction,
        icon: GLYPHS.ICON_REFRESH
      };
    }
    const emptyStateDataComponent = (
      <HubEmptyStateRestyle
        iconSrc={emptyStateData.iconSrc}
        title={emptyStateData.title}
        bodyText={emptyStateData.subtitle}
        btnFilledBase={currentOrganisationLti ? null : filtersEmptyStateButton}
      />
    );

    return (
      <div>
        {loadingResults ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : (
          <div>
            {hasAssignmentsForClass && (
              <HubProfilePageMaterialFilter userId={personId} hubContent={hubContent} products={userAssignments} />
            )}

            {shouldShowProductsForClassUser || licencesContext === ORG ? (
              <EnhancedHubListPage
                lists={[filteredAssignmentIds]}
                headerItems={getOrgLicencesHeaderItems(
                  hubContent,
                  { openAddLicensesModalAction: assignMaterial },
                  headerOptions
                )}
                currentUsersList={learningAssignments}
                userAssignments={products}
                breakpoint={breakpoint}
                hubContent={hubContent}
                userRole={userRole}
                hasHeaderButtonAccess={!isLtiMode()}
                showCheckboxHeader={false}
                dropdownActions={dropdownActions}
                dropdownItemsType="userLicences"
                emptyStateData={emptyStateData}
                totalResults={totalResults}
                licencesContext={licencesContext}
                isTeacherAssignedToClass={isTeacherAssignedToClass}
                isUserProfilePage
                currentOrganisationLti={currentOrganisationLti}
                productInformationContext={productInformationContext.USER_PROFILE_MATERIALS}
              />
            ) : (
              emptyStateDataComponent
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withBreakpoint,
  withSearchInitialiser({
    searchSource: 'libProducts',
    initialFilters: { showOnlyRedeemed: true }
  }),
  withLocalizedContent('hubGlossary'),
  withDataRecency('orgLicences'),
  connect(
    state => ({
      userAssignments: get(state, ['search', 'userAssignments', 'data']),
      loadingResults: get(state, ['search', 'userAssignments', 'loading']),
      products: state.products.data,
      currentOrganisationId: state.identity.currentOrganisationId,
      currentOrganisationLti: state.identity.currentOrganisationLti,
      userRole: get(state, ['identity', 'role']),
      classname: state.classroomEdit.classroomNameValue || '',
      recallLicenceModalOpen: state.hubUi.recallLicenceModalOpen,
      formState: state.licenceRecall.formState,
      licenceRecallFailed: state.licenceRecall.failed,
      productData: (state.hubProfileCourseMaterialFilters && state.hubProfileCourseMaterialFilters.data) || {},
      people: state.people.data
    }),
    {
      loadLicenses: initialiseInstance,
      toggleDetailsPopupWithIdAction: toggleDetailsPopupWithId,
      initialiseAssignLicence: bypassProductSelection,
      initialiseRecall: initialiseForm,
      openRecallLicenceModalAction: openRecallLicenceModal,
      resetFiltersProfileCourseMaterialsAction: resetFiltersProfileCourseMaterials,
      setProductFinderDataAction: setProductFinderData
    }
  )
)(HubPersonProfileLicenses);

HubPersonProfileLicenses.propTypes = {
  breakpoint: PropTypes.string,
  localizedContent: PropTypes.object,
  loadingResults: PropTypes.bool,
  loadLicenses: PropTypes.func,
  currentOrganisationId: PropTypes.string,
  currentOrganisationLti: PropTypes.bool,
  toggleDetailsPopupWithIdAction: PropTypes.func,
  personId: PropTypes.string,
  personUsertype: PropTypes.string,
  userRole: PropTypes.string,
  userAssignments: PropTypes.object,
  productData: PropTypes.object,
  licencesContext: PropTypes.string,
  classname: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  isTeacherAssignedToClass: PropTypes.bool,
  assignMaterial: PropTypes.func,
  personFirstname: PropTypes.string.isRequired,
  initialiseAssignLicence: PropTypes.func,
  initialiseRecall: PropTypes.func.isRequired,
  openRecallLicenceModalAction: PropTypes.func.isRequired,
  formState: PropTypes.string.isRequired,
  licenceRecallFailed: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  resetFiltersProfileCourseMaterialsAction: PropTypes.func,
  setProductFinderDataAction: PropTypes.func,
  people: PropTypes.object
};
