import React from 'react';

// Styles
import styles from './MyDashboardStudentView.scss';

// Components
import LastOpened from './LastOpened';

function MyDashboardStudentView() {
  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.lastOpenArea}>
        <LastOpened />
      </div>
      <div className={styles.wordOfTheDay}>
        <h2>Word of the Day</h2>
        <p>Content for the word of the day...</p>
      </div>
    </div>
  );
}

export default MyDashboardStudentView;
