import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';

import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardWelcome({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  firstName
}) {
  return (
    <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_WELCOME_CONTAINER">
      <WizardStep
        titleText={`${hubContent.self_select_role_title.replace('{firstName}', firstName)}`}
        subtitleText={content.self_registered_user_onboarding_wizard_set_up}
        image="/media/feedback/illustration-welcome.svg"
        imageAlt="Welcome to Oxford English Hub"
        variant="introduction"
        headingSize="large"
        className={wizardStyles.yellowBackground}
      />
    </div>
  );
}

SelfRegisteredUserOnboardingWizardWelcome.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  SelfRegisteredUserOnboardingWizardWelcome
);
