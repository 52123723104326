import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { setContextValue, setRoleValue } from '../../redux/reducers/enrolUser.reducer';
import {
  clearSelectedUser,
  setStudentSelected,
  setTeacherSelected,
  setActiveOrgId
} from '../../redux/reducers/organisationPage.reducer';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import {
  closeAddLicensesModal,
  openAddLicensesModal,
  toggleOrgModalOpen,
  openCreateClassModal,
  openOnboardingWizard,
  toggleDeploymentToolModal
} from '../../redux/actions/hubUi';
import { setStaffToArchive, setStudentsToArchive } from '../../redux/reducers/archiveUsers.reducer';
import { getUserDetails, setUserDetails, sendReminder } from '../../redux/reducers/user/userProfile.reducer';
import { resetPasswordRequest } from '../../redux/actions/resetPassword';
import { createAuthorizeRemoveUser } from '../../redux/selectors/authorization/user';
import { createAuthorizeUpdateAssignments } from '../../redux/selectors/authorization/organization';
import { editLMSConfigTool, archiveLMSConfigTool, resetForm } from '../../redux/actions/lmsConfig';
import { setRegenerateSignInCardRequestData } from '../../redux/actions/regenerateSupervisedUserSignInCard';
import withLocalizedContent from '../../language/withLocalizedContent';
// Style
import styles from './HubOrganizationLayout.scss';
// Constants
import breakpoints from '../../globals/breakpoints';
import { HubLayoutConstants } from '../../globals/hubConstants';
import userRoles from '../../globals/userRoles';
// Components
import OrganizationSubMenu from './OrganizationSubMenu/OrganizationSubMenu';
import HubOrgPanelContainer from './Panels/HubOrgPanelContainer';
import { resetProductFinderState } from '../../redux/actions/productFinderActions';
import loadLMSConfig from '../HubDashboardLayout/Services/loadLMSConfig';
import { featureIsEnabled } from '../../globals/envSettings';
import HubPageLayout from '../HubPageLayout/HubPageLayout';

function HubOrganizationLayout({
  localizedContent: { mySchoolLibraryTab: content },
  location: { pathname },
  orgId,
  setActiveOrgIdAction,
  initialiseSearchInstance,
  userId,
  currentOrganisationLti,
  setContextValueAction,
  setRoleValueAction,
  orgPending,
  setStudentsToArchiveAction,
  setStaffToArchiveAction,
  clearSelectedUserAction,
  toggleOrgModalOpenAction,
  resetProductFinderStateAction,
  setUserDetailsAction,
  getUserDetailsAction,
  sendReminderAction,
  sendPasswordResetEmailAction,
  setStudentSelectedAction,
  setTeacherSelectedAction,
  setRegenerateSignInCardRequestDataAction,
  setDeploymentToolEditAction,
  setDeploymentToolArchiveAction,
  breakpoint,
  hubContent,
  children,
  userRole,
  closeAddLicensesModalAction,
  currentOrganisationName,
  addLicensesModalOpen,
  openAddLicensesModalAction,
  openCreateClassModalAction,
  openOnboardWizardAction,
  selectedStudentIds,
  selectedTeacherIds,
  canRemoveOtherUser,
  canManageAssignments,
  toggleDeploymentToolModalAction,
  resetDeploymentToolModalAction,
  deploymentToolModal,
  people,
  isLmsLtiTool = false,
  pageLayout = {}
}) {
  const [panelName, setPanelName] = useState('');
  const [panelContext, setPanelContext] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserRole, setSelectedUserRole] = useState('');
  const [selectedUserType, setSelectedUserType] = useState('');
  const [panelData, setPanelData] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (currentOrganisationLti) loadLMSConfig(initialiseSearchInstance, orgId, userId);
    setActiveOrgIdAction(orgId);
  }, []);

  const onCompleteModal = panel => {
    setPanelName('');
    if (!panel) {
      closeAddLicensesModalAction();
      history.push('/myOrganization/licences');
      return;
    }
    if (panel === 'addStaff' || panel === 'addStudents') {
      const endpoint = panel === 'addStaff' ? '/myOrganization/staff' : '/myOrganization/students';
      history.push(endpoint);
    }
  };

  const openModal = (newPanelName = '', data = {}) => {
    const { role = '', selectedUserId: newSelectedUserId = '', selectedUsers = [], context = '' } = data;
    const orgContext = orgPending ? 'ORG_ADMIN' : 'ORG_STAFF';
    const newContext = context === APP_CONSTANTS.ORG_STUDENTS ? APP_CONSTANTS.ORG_STUDENTS : orgContext;
    const newSelectedUserType = context === APP_CONSTANTS.ORG_STUDENTS ? 'students' : 'staff';

    setPanelName(newPanelName);
    setPanelContext(newContext);
    setSelectedUserId(newSelectedUserId);
    setSelectedUserRole(role);
    setSelectedUserType(newSelectedUserType);
    setPanelData(data);

    toggleOrgModalOpenAction();

    if (selectedUsers?.length && newPanelName === 'archiveUsers') {
      if (context === APP_CONSTANTS.ORG_STUDENTS) setStudentsToArchiveAction(selectedUsers);
      else setStaffToArchiveAction(selectedUsers);
      return;
    }

    setContextValueAction(newContext);
    setRoleValueAction(role);
  };

  const closeOrgModal = () => {
    setPanelName('');
    clearSelectedUserAction();
    resetProductFinderStateAction();
    toggleOrgModalOpenAction();
  };

  const openModalFromUserDropdown = (e, newPanelName = '', data = {}) => {
    const { userDetails, context = '', selectedUsers = [] } = data;
    if (e?.stopPropagation) {
      e.stopPropagation();
    }

    openModal(newPanelName, data);

    if (selectedUsers?.length && (newPanelName === 'editUser' || newPanelName === 'cancelInvitation')) {
      setUserDetailsAction({ ...userDetails, orgName: currentOrganisationName }, {});
      getUserDetailsAction(orgId, selectedUsers);
      return;
    }
    if (selectedUsers?.length && newPanelName === 'editUser/sendPasswordResetEmail') {
      setPanelName(newPanelName.split('/')[0]);
      getUserDetailsAction(orgId, selectedUsers);
      setUserDetailsAction({ ...userDetails, orgName: currentOrganisationName }, {});
      sendPasswordResetEmailAction(userDetails.email);
      return;
    }

    if (selectedUsers?.length && newPanelName === 'editUser/sendInvitationReminder') {
      setPanelName(newPanelName.split('/')[0]);
      getUserDetailsAction(orgId, selectedUsers);
      setUserDetailsAction({ ...userDetails, orgName: currentOrganisationName }, {});
      sendReminderAction(history);
      return;
    }

    if (newPanelName === 'assignMaterial') {
      setPanelName(newPanelName);
      if (selectedUsers?.length) {
        clearSelectedUserAction();
        if (context === APP_CONSTANTS.ORG_STUDENTS) {
          setStudentSelectedAction(selectedUsers, orgId);
        } else {
          setTeacherSelectedAction(selectedUsers, orgId);
        }
      }
    }

    if (newPanelName === HubLayoutConstants.PEOPLE_PANEL_TYPES.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD) {
      setPanelName(newPanelName);
      setRegenerateSignInCardRequestDataAction({
        userId: selectedUsers[0],
        userName: data.userDetails.userName,
        firstName: data.userDetails.firstName,
        lastName: data.userDetails.lastName
      });

      if (newPanelName === HubLayoutConstants.PEOPLE_PANEL_TYPES.BULK_PASSWORD_RESET) {
        setPanelName(newPanelName);
      }
    }
  };

  const navigateArchiveOrgUser = () => {
    if (selectedUserRole === userRoles.LEARNER) {
      setStudentsToArchiveAction([selectedUserId]);
    } else {
      setStaffToArchiveAction([selectedUserId]);
    }
    setPanelName('archiveUsers');
  };
  const openDeploymentToolModalFromUserDropDown = (e, toolId, mode) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (mode === 'edit') {
      toggleDeploymentToolModalAction();
      setDeploymentToolEditAction(toolId);
    }
    if (mode === 'archive') {
      toggleDeploymentToolModalAction();
      setDeploymentToolArchiveAction(toolId);
    }
  };

  const actions = {
    addStaff: (e, panel, data) => openModal(panel, data),
    addStudents: (e, panel, data) => openModal(panel, data),
    removeUsers: (e, panel, data) =>
      openModal(panel, {
        ...data,
        selectedUsers: data.context === APP_CONSTANTS.ORG_STUDENTS ? selectedStudentIds : selectedTeacherIds
      }),
    ...(featureIsEnabled('bulk-password-reset') && {
      bulkPasswordReset: (e, panel, data) => {
        e?.stopPropagation();
        return openModalFromUserDropdown(e, panel, { ...data, selectedUserIds: selectedStudentIds });
      }
    }),
    openAddLicensesModalAction,
    toggleDeploymentToolModalAction,
    assignMaterial: (e, panel, data) => {
      if (e?.stopPropagation) {
        e.stopPropagation();
      }
      return openModalFromUserDropdown(e, panel, data);
    },
    openCreateClassModalAction,
    openOnboardWizardAction,
    regenerateSupervisedUserSignInCard: (e, panel, data) => {
      if (e?.stopPropagation) {
        e.stopPropagation();
      }
      return openModalFromUserDropdown(e, panel, data);
    }
  };

  const userDropdownActions = {
    removeUser: openModalFromUserDropdown,
    editUser: openModalFromUserDropdown,
    cancelInvitation: openModalFromUserDropdown,
    assignMaterial: openModalFromUserDropdown,
    setDeploymentToolEditAction: (e, id) => openDeploymentToolModalFromUserDropDown(e, id, 'edit'),
    setDeploymentToolArchiveAction: (e, id) => openDeploymentToolModalFromUserDropDown(e, id, 'archive'),
    regenerateSupervisedUserSignInCard: openModalFromUserDropdown
  };

  const panelsActions = {
    onCompleteModal,
    closeOrgModal,
    closeAddLicensesModalAction,
    navigateArchiveOrgUser,
    addLicensesModalOpen,
    deploymentToolModal,
    toggleDeploymentToolModalAction,
    resetDeploymentToolModalAction
  };

  const panelAccess = {
    canRemoveOtherUser,
    canManageAssignments
  };

  const _render = () => (
    <div className={styles.organizationLayout}>
      {(breakpoint !== breakpoints.XXS || HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH === pathname) &&
        !featureIsEnabled('navigation-changes') && (
          <div className={styles.organizationLayoutSubMenu}>
            <OrganizationSubMenu
              isLmsLtiTool={isLmsLtiTool}
              actions={actions}
              hubContent={hubContent}
              pathname={pathname}
              userRole={userRole}
            />
          </div>
        )}

      {(breakpoint !== breakpoints.XXS || HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH !== pathname) && (
        <div className={styles.organizationChildrenContainer}>
          {children &&
            React.cloneElement(children, {
              breakpoint,
              push: history.push,
              hubContent,
              actions,
              userDropdownActions,
              orgId
            })}
        </div>
      )}

      <HubOrgPanelContainer
        hubContent={hubContent}
        content={content}
        orgId={orgId}
        currentOrganisationName={currentOrganisationName}
        selectedUserId={selectedUserId}
        selectedUserType={selectedUserType}
        panelName={panelName}
        panelContext={panelContext}
        panelsActions={panelsActions}
        panelAccess={panelAccess}
        panelData={panelData}
        people={people}
      />
    </div>
  );
  if (featureIsEnabled('navigation-changes')) {
    return <HubPageLayout {...pageLayout}>{_render()}</HubPageLayout>;
  }
  return _render();
}

export default compose(
  withLocalizedContent('mySchoolLibraryTab'),
  connect(
    state => ({
      userId: state.identity.userId,
      userRole: state.identity.role,
      orgPending: state.editOrg.registrationStatus === APP_CONSTANTS.ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED,
      orgId: state.identity.currentOrganisationId,
      addLicensesModalOpen: state.hubUi.addLicensesModalOpen,
      deploymentToolModal: state.hubUi.deploymentToolModal,
      currentOrganisationName: state.organisations.data[state.identity.currentOrganisationId].name,
      selectedStudentIds: state.organisationPage.selectedStudentIds,
      selectedTeacherIds: state.organisationPage.selectedTeacherIds,
      people: state.people.data,
      isLmsLtiTool: state.organisationPage.isLmsLtiTool,
      currentOrganisationLti: state.identity.currentOrganisationLti,
      canRemoveOtherUser: createAuthorizeRemoveUser(state)({ orgId: state.identity.currentOrganisationId }),
      canManageAssignments: createAuthorizeUpdateAssignments(state)({ orgId: state.identity.currentOrganisationId })
    }),
    {
      setContextValueAction: setContextValue,
      setRoleValueAction: setRoleValue,
      setActiveOrgIdAction: setActiveOrgId,
      initialiseSearchInstance: initialiseInstance,
      closeAddLicensesModalAction: closeAddLicensesModal,
      openAddLicensesModalAction: openAddLicensesModal,
      setStudentsToArchiveAction: setStudentsToArchive,
      toggleOrgModalOpenAction: toggleOrgModalOpen,
      clearSelectedUserAction: clearSelectedUser,
      setStudentSelectedAction: setStudentSelected,
      setTeacherSelectedAction: setTeacherSelected,
      setUserDetailsAction: setUserDetails,
      getUserDetailsAction: getUserDetails,
      sendPasswordResetEmailAction: resetPasswordRequest,
      sendReminderAction: sendReminder,
      setStaffToArchiveAction: setStaffToArchive,
      openCreateClassModalAction: openCreateClassModal,
      openOnboardWizardAction: openOnboardingWizard,
      toggleDeploymentToolModalAction: toggleDeploymentToolModal,
      resetDeploymentToolModalAction: resetForm,
      setDeploymentToolEditAction: editLMSConfigTool,
      setDeploymentToolArchiveAction: archiveLMSConfigTool,
      resetProductFinderStateAction: resetProductFinderState,
      setRegenerateSignInCardRequestDataAction: setRegenerateSignInCardRequestData
    }
  )
)(withRouter(HubOrganizationLayout));

HubOrganizationLayout.propTypes = {
  location: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  children: PropTypes.object,
  userRole: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  orgPending: PropTypes.bool.isRequired,
  setContextValueAction: PropTypes.func.isRequired,
  setRoleValueAction: PropTypes.func.isRequired,
  setActiveOrgIdAction: PropTypes.func.isRequired,
  addLicensesModalOpen: PropTypes.bool,
  deploymentToolModal: PropTypes.bool,
  currentOrganisationName: PropTypes.string,
  localizedContent: PropTypes.object,
  closeAddLicensesModalAction: PropTypes.func.isRequired,
  openAddLicensesModalAction: PropTypes.func.isRequired,
  setStudentsToArchiveAction: PropTypes.func.isRequired,
  setStaffToArchiveAction: PropTypes.func.isRequired,
  selectedStudentIds: PropTypes.array.isRequired,
  selectedTeacherIds: PropTypes.array.isRequired,
  people: PropTypes.object,
  clearSelectedUserAction: PropTypes.func.isRequired,
  setStudentSelectedAction: PropTypes.func.isRequired,
  setTeacherSelectedAction: PropTypes.func.isRequired,
  sendReminderAction: PropTypes.func.isRequired,
  setUserDetailsAction: PropTypes.func.isRequired,
  sendPasswordResetEmailAction: PropTypes.func.isRequired,
  getUserDetailsAction: PropTypes.func.isRequired,
  canRemoveOtherUser: PropTypes.bool.isRequired,
  canManageAssignments: PropTypes.bool.isRequired,
  toggleOrgModalOpenAction: PropTypes.func,
  openCreateClassModalAction: PropTypes.func.isRequired,
  openOnboardWizardAction: PropTypes.func.isRequired,
  toggleDeploymentToolModalAction: PropTypes.func.isRequired,
  resetDeploymentToolModalAction: PropTypes.func.isRequired,
  setDeploymentToolEditAction: PropTypes.func.isRequired,
  setDeploymentToolArchiveAction: PropTypes.func.isRequired,
  resetProductFinderStateAction: PropTypes.func,
  isLmsLtiTool: PropTypes.bool,
  initialiseSearchInstance: PropTypes.any,
  userId: PropTypes.string,
  currentOrganisationLti: PropTypes.bool,
  setRegenerateSignInCardRequestDataAction: PropTypes.func,
  pageLayout: PropTypes.shape({
    ...HubPageLayout.propTypes,
    showPageInnerHero: PropTypes.bool
  })
};
