/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isEmpty from 'lodash.isempty';
import { withRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import classnames from 'classnames';
import { initialiseInstance, setPageClass } from '../../redux/reducers/data/search.reducer';
import { getUserDetails, sendReminder } from '../../redux/reducers/user/userProfile.reducer';
import { loadClassDetails } from '../../redux/reducers/data/classrooms';
import {
  setActiveClassId,
  clearSelectedUser,
  setStudentSelected,
  setTeacherSelected
} from '../../redux/reducers/classroomPage';
import { hubDownloadRemoveErrorModal } from '../../redux/actions/hubResource';
import { openEditClassroomModal, toggleClassModalOpen } from '../../redux/actions/hubUi';
import { setRegenerateSignInCardRequestData } from '../../redux/actions/regenerateSupervisedUserSignInCard';
import { setUsers } from '../../redux/reducers/removeStudentsFromClassroom';
import { setTeachersToRemove } from '../../redux/reducers/removeTeachersFromClassroom';
import { setMultipleUsers } from '../../redux/reducers/hub/hubRemoveUsersFromClassroom.reducer';
import { isAtLeastTeacher } from '../../redux/selectors/authorization/user';
import {
  setContext,
  resetStateForInstance as resetRemoveMaterialPanel
} from '../../redux/reducers/removeLearningMaterial.reducer';
import { switchToPage } from '../../redux/actions/onlineTestActions';
import { resetPasswordRequest } from '../../redux/actions/resetPassword';
// Constants
import breakpoints from '../../globals/breakpoints';
import { HubLayoutConstants } from '../../globals/hubConstants';
// Utils
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import getClassLink from '../HubDashboardLayout/Services/getClassLink';
import getOrganizationClassLink from '../HubDashboardLayout/Utils/getOrganizationClassLink';
import loadPeopleDetails from '../HubDashboardLayout/Services/loadPeopleDetails';
import processUserRole from '../HubDashboardLayout/Services/processUserRole';
import getClassContext from './Utils/getClassContext';
import { featureIsEnabled } from '../../globals/envSettings';
// Style
import styles from './HubClassLayout.scss';
// Components
import ClassSubMenu from './ClassSubMenu/ClassSubMenu';
import PanelContainer from './Panels/PanelContainer';
import ResourceFormHidden from '../HubProductLayout/Resources/ResourceFormHidden';
import HubPageLayout from '../HubPageLayout/HubPageLayout';

class HubClassLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelType: '',
      panelData: {}
    };

    const {
      match: { params },
      switchToPageAction
    } = this.props;

    switchToPageAction({
      page: HubLayoutConstants.ONLINE_TEST_STEPS.CHOOSE_TEST_PAGE,
      classId: params.classroomId
    });
  }

  componentDidMount() {
    const {
      props: {
        match: {
          params: { classroomId }
        },
        orgId,
        loadClassDetailsAction,
        setActiveClassIdAction,
        initialiseSearch,
        userRole,
        setContextAction
      },
      getClassAssignments,
      getProfileAssignments
    } = this;
    if (userRole === userRoles.USER) return;
    getProfileAssignments();
    if (userRole === userRoles.LEARNER) return;

    getClassAssignments();
    loadClassDetailsAction(orgId, classroomId);
    setActiveClassIdAction(orgId, classroomId);
    loadPeopleDetails(initialiseSearch, orgId, classroomId);

    setContextAction('CLASS');
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        match: {
          params: { classroomId }
        },
        orgId,
        userRole,
        loadClassDetailsAction,
        setActiveClassIdAction,
        initialiseSearch,
        endSlice,
        setPeoplePage,
        peoplePage,
        currentPage,
        switchToPageAction
      },
      getClassAssignments
    } = this;

    if (prevProps.match.params.classroomId !== classroomId && roleIsAtLeast(userRoles.TEACHER, userRole)) {
      getClassAssignments();
    }

    if (userRole === userRoles.LEARNER) return;

    if (prevProps.match.params.classroomId !== classroomId) {
      loadClassDetailsAction(orgId, classroomId);
      setActiveClassIdAction(orgId, classroomId);
      loadPeopleDetails(initialiseSearch, orgId, classroomId);
    }
    if (prevProps.endSlice !== endSlice && prevProps.peoplePage !== peoplePage) {
      ['classStudents', 'classTeachers'].forEach(t => setPeoplePage(t, peoplePage));
    }

    if (currentPage === HubLayoutConstants.ONLINE_TEST_STEPS.CHOOSE_CLASS_PAGE) {
      switchToPageAction({
        page: HubLayoutConstants.ONLINE_TEST_STEPS.CHOOSE_TEST_PAGE,
        classId: classroomId
      });
    }
  }

  componentWillUnmount() {
    const {
      currentPage,
      switchToPageAction,
      match: { params }
    } = this.props;

    if (currentPage !== HubLayoutConstants.ONLINE_TEST_STEPS.CHOOSE_CLASS_PAGE) {
      switchToPageAction({
        page: HubLayoutConstants.ONLINE_TEST_STEPS.CHOOSE_CLASS_PAGE,
        classId: params.classroomId
      });
    }
  }

  getClassAssignments = () => {
    const {
      props: {
        match: {
          params: { classroomId }
        },
        orgId,
        initialiseSearch
      }
    } = this;
    initialiseSearch(
      'classAssignments',
      false,
      {
        classId: classroomId,
        orgId,
        searchAssignments: true,
        active: true
      },
      undefined,
      'name:asc'
    );
  };

  getProfileAssignments = () => {
    const {
      props: { userId, initialiseSearch, userRole }
    } = this;

    const targetUsertype =
      userRole === userRoles.LEARNER
        ? HubLayoutConstants.TARGET_USERTYPE.STUDENT
        : HubLayoutConstants.TARGET_USERTYPE.TEACHER;
    initialiseSearch(
      'profileAssignments',
      false,
      {
        userId,
        targetUsertype,
        searchAssignments: true,
        active: true,
        expired: true,
        noLicence: true,
        expiring: true,
        notStarted: true
      },
      undefined,
      'name:asc'
    );
  };

  handleInvitationReminder = (e, panelType = '', data = {}) => {
    const {
      props: { orgId, getUserDetailsAction, sendReminderAction, toggleClassModalOpenAction, history }
    } = this;
    const { selectedUserId = '' } = data;
    this.setState(() => ({
      panelType
    }));

    getUserDetailsAction(orgId, selectedUserId);
    sendReminderAction(history);
    toggleClassModalOpenAction();
  };

  handleRemoveFromClass = (e, panelType = '', data = {}) => {
    const {
      props: {
        setStudentsAction,
        setTeachersAction,
        setMultipleUsersAction,
        submittedUsers,
        toggleClassModalOpenAction
      }
    } = this;
    const { selectedUserId = '' } = data;
    const {
      PEOPLE_PANEL_TYPES: { REMOVE_STUDENTS, REMOVE_TEACHERS }
    } = HubLayoutConstants;

    this.setState(() => ({
      panelType
    }));

    toggleClassModalOpenAction();

    if (panelType === REMOVE_STUDENTS) {
      setStudentsAction([selectedUserId]);
      return;
    }
    if (panelType === REMOVE_TEACHERS) {
      setTeachersAction(selectedUserId);
      return;
    }
    setMultipleUsersAction(submittedUsers);
  };

  handleAssignMaterials = (e, panelType = '', data = {}) => {
    const {
      canManageAssignments,
      setStudentSelectedAction,
      clearSelectedUserAction,
      setTeacherSelectedAction,
      toggleClassModalOpenAction
    } = this.props;
    const { TEACHER, LEARNER } = userRoles;
    const { role = '', selectedUserId = '' } = data;
    // Make the selection when have single selectedUserId & role
    if (role === LEARNER) {
      clearSelectedUserAction();
      setStudentSelectedAction(selectedUserId, true);
    } else if (role === TEACHER) {
      clearSelectedUserAction();
      setTeacherSelectedAction(selectedUserId, true);
    }

    if (canManageAssignments) {
      this.setState({ panelType, panelData: data });
      toggleClassModalOpenAction();
    }
  };

  handleRemoveMaterialsFromClass = (panelType = '') => {
    this.setState({ panelType });
  };

  handleOnCompleteRemoveMaterialsFromClass = () => {
    const { resetRemoveMaterialPanelAction } = this.props;
    resetRemoveMaterialPanelAction();
    this.getClassAssignments();
    this.getProfileAssignments();
  };

  handleSendPasswordResetEmail = (e, panelType = '', data) => {
    const {
      props: { sendPasswordResetEmailAction, toggleClassModalOpenAction }
    } = this;
    const { selectedUserEmail } = data;
    this.setState(() => ({
      panelType,
      panelData: data
    }));
    sendPasswordResetEmailAction(selectedUserEmail);
    toggleClassModalOpenAction();
  };

  handleRegenerateSupervisedUserSignInCard = (e, panelType = '', data) => {
    const {
      props: { setRegenerateSignInCardRequestDataAction, toggleClassModalOpenAction }
    } = this;
    this.setState(() => ({
      panelType,
      panelData: data
    }));

    setRegenerateSignInCardRequestDataAction({
      userId: data.selectedUsers[0],
      userName: data.userDetails.userName,
      firstName: data.userDetails.firstName,
      lastName: data.userDetails.lastName
    });
    toggleClassModalOpenAction();
  };

  manageProductLocks = productDetails => {
    const {
      match: {
        params: { classroomId }
      }
    } = this.props;

    this.setState(() => ({
      panelType: HubLayoutConstants.CLASSWORK_PANEL_TYPES.LOCK_PRODUCT_CONTENT,
      panelData: { classId: classroomId, productDetails }
    }));
  };

  handleBulkPasswordReset = (e, panelType, { selectedUsers } = []) => {
    const {
      props: { toggleClassModalOpenAction }
    } = this;
    this.setState(() => ({
      panelType,
      panelData: { selectedUserIds: selectedUsers }
    }));
    toggleClassModalOpenAction();
  };

  closePopupPanel = () => {
    const {
      props: { clearSelectedUserAction, toggleClassModalOpenAction }
    } = this;
    toggleClassModalOpenAction();
    clearSelectedUserAction();
    this.setState(() => ({ panelType: '', panelData: {} }));
  };

  _getProductFinderSelectedUsers = () => {
    const { personId, people, classUserIds = [], materialsToClassModalOpen } = this.props;
    const { panelData } = this.state;
    const selectedUsers = { teacherIdList: [], studentIdList: [] };
    if (!materialsToClassModalOpen) {
      if (panelData.selectedUsers?.length) {
        panelData.selectedUsers.forEach(userId => {
          if (people[userId].roleName === userRoles.TEACHER) {
            selectedUsers.teacherIdList.push(userId);
          } else if (people[userId].roleName === userRoles.LEARNER) {
            selectedUsers.studentIdList.push(userId);
          }
        });
        return selectedUsers;
      }
      if (people[personId]?.roleName === userRoles.TEACHER) {
        selectedUsers.teacherIdList.push(personId);
      } else if (people[personId]?.roleName === userRoles.LEARNER) {
        selectedUsers.studentIdList.push(personId);
      }
      return selectedUsers;
    }
    return classUserIds;
  };

  _getProductFinderContextName = () => {
    const { classUserIds, people, productFinderContent, materialsToClassModalOpen, classnameValue } = this.props;
    const { panelData } = this.state;
    const selectedUsers = materialsToClassModalOpen ? classUserIds : panelData?.selectedUsers || [];
    if (!materialsToClassModalOpen) {
      return selectedUsers?.length === 1
        ? `${people[selectedUsers[0]]?.firstname || ''} ${people[selectedUsers[0]]?.lastname || ''}`
        : `${selectedUsers.length} ${productFinderContent.users_text}`;
    }
    return classnameValue;
  };

  render() {
    const {
      props: {
        match: { params },
        match: {
          params: { classroomId }
        },
        location: { pathname },
        history: { push },
        classTitle,
        userId,
        orgId,
        userRole,
        breakpoint,
        hubContent,
        productFinderContent,
        children,
        currentClassList,
        hasProductsInGradebook,
        isGradebookLoading,
        isError,
        sideNavOpen,
        downloadType,
        hubDownloadRemoveErrorModalAction,
        assignmentId,
        openEditClassroomModalAction,
        canManageAssignments,
        materialsToClassModalOpen,
        materialsToClassModalContent,
        pageLayout
      },
      state: { panelType, panelData },
      handleRemoveFromClass,
      handleAssignMaterials,
      handleInvitationReminder,
      getProfileAssignments,
      getClassAssignments,
      closePopupPanel,
      handleRemoveMaterialsFromClass,
      handleBulkPasswordReset,
      handleOnCompleteRemoveMaterialsFromClass,
      handleSendPasswordResetEmail,
      handleRegenerateSupervisedUserSignInCard,
      manageProductLocks
    } = this;

    const processedUserRole = processUserRole(userRole, hubContent);

    // Check if teacher is assigned to a class or not
    const teacherIdList = get(currentClassList, [classroomId, 'teacherIdList'], []);
    const isTeacherAssignedToClass =
      includes(teacherIdList, userId) || roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole);

    const userDropdownActions = {
      removeUser: handleRemoveFromClass,
      assignMaterial: handleAssignMaterials,
      ...(featureIsEnabled('bulk-password-reset') && { bulkPasswordReset: handleBulkPasswordReset }),
      invitationReminder: handleInvitationReminder,
      sendPasswordResetEmail: handleSendPasswordResetEmail,
      regenerateSupervisedUserSignInCard: handleRegenerateSupervisedUserSignInCard
    };

    const getAssignmentsActions = {
      profileAssignments: getProfileAssignments,
      classAssignments: getClassAssignments
    };

    const classLayoutPanelActions = {
      handleOnCompleteRemoveMaterialsFromClass
    };

    const classworkPageActions = {
      handleRemoveMaterialsFromClass,
      manageProductLocks
    };

    const isGradebookPage = pathname.includes(HubLayoutConstants.PATH_NAMES.GRADEBOOK);
    const context = getClassContext(pathname, classroomId);

    const renderClassLayout = () => (
      <div
        className={classnames(styles.hubClassLayout, {
          [styles.navigationChanges]: featureIsEnabled('navigation-changes')
        })}
      >
        {!featureIsEnabled('navigation-changes') &&
          (breakpoint !== breakpoints.XXS ||
            getClassLink(classroomId) === pathname ||
            getOrganizationClassLink(classroomId) === pathname) && (
            <div className={styles.hubClassLayoutSubMenu}>
              <ClassSubMenu
                params={params}
                classId={classroomId}
                hubContent={hubContent}
                pathname={pathname}
                orgId={orgId}
                userRole={userRole}
                processedUserRole={processedUserRole}
                isTeacherAssignedToClass={isTeacherAssignedToClass}
                openEditClassroomModal={openEditClassroomModalAction}
                context={context}
              />
            </div>
          )}
        {(breakpoint !== breakpoints.XXS ||
          (getClassLink(classroomId) !== pathname && getOrganizationClassLink(classroomId) !== pathname)) && (
          <div
            className={classnames(styles.hubClassChildrenContainer, {
              [styles.navOpen]: sideNavOpen,
              [styles.gradebookPage]: isGradebookPage
            })}
          >
            {children &&
              React.cloneElement(children, {
                classroomId,
                classTitle,
                breakpoint,
                hubContent,
                productFinderContent,
                orgId,
                hasProductsInGradebook,
                isGradebookLoading,
                isError,
                downloadType,
                hubDownloadRemoveErrorModalAction,
                assignmentId,
                getAssignmentsActions,
                processedUserRole,
                isTeacherAssignedToClass,
                userDropdownActions,
                classworkPageActions,
                push,
                context
              })}
          </div>
        )}

        <PanelContainer
          closePopupPanel={closePopupPanel}
          panelType={panelType}
          panelData={panelData}
          materialsToClassModalOpen={materialsToClassModalOpen}
          materialsToClassModalContent={materialsToClassModalContent}
          orgId={orgId}
          classId={classroomId}
          canManageAssignments={canManageAssignments}
          hubContent={hubContent}
          actions={classLayoutPanelActions}
          selectedUsers={this._getProductFinderSelectedUsers()}
          contextName={this._getProductFinderContextName()}
        />
        <ResourceFormHidden />
      </div>
    );

    return featureIsEnabled('navigation-changes') ? (
      <HubPageLayout {...pageLayout}>{renderClassLayout()}</HubPageLayout>
    ) : (
      renderClassLayout()
    );
  }
}

HubClassLayout.defaultProps = {
  hasProductsInGradebook: false,
  currentClassList: {},
  orgId: ''
};

HubClassLayout.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  productFinderContent: PropTypes.object.isRequired,
  children: PropTypes.object,
  orgId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  personId: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  people: PropTypes.object,
  currentClassList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classTitle: PropTypes.string,
  initialiseSearch: PropTypes.func.isRequired,
  setPeoplePage: PropTypes.func.isRequired,
  endSlice: PropTypes.number.isRequired,
  peoplePage: PropTypes.number.isRequired,
  loadClassDetailsAction: PropTypes.func.isRequired,
  setActiveClassIdAction: PropTypes.func.isRequired,
  hasProductsInGradebook: PropTypes.bool,
  isGradebookLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  hubDownloadRemoveErrorModalAction: PropTypes.func.isRequired,
  downloadType: PropTypes.string.isRequired,
  assignmentId: PropTypes.string,
  openEditClassroomModalAction: PropTypes.func.isRequired,
  setStudentsAction: PropTypes.func.isRequired,
  setTeachersAction: PropTypes.func.isRequired,
  submittedUsers: PropTypes.array.isRequired,
  classUserIds: PropTypes.object,
  classnameValue: PropTypes.string,
  setMultipleUsersAction: PropTypes.func.isRequired,
  clearSelectedUserAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  sideNavOpen: PropTypes.bool,
  canManageAssignments: PropTypes.bool,
  setTeacherSelectedAction: PropTypes.func,
  setStudentSelectedAction: PropTypes.func,
  sendReminderAction: PropTypes.func.isRequired,
  getUserDetailsAction: PropTypes.func.isRequired,
  toggleClassModalOpenAction: PropTypes.func,
  setContextAction: PropTypes.func.isRequired,
  resetRemoveMaterialPanelAction: PropTypes.func.isRequired,
  switchToPageAction: PropTypes.func,
  currentPage: PropTypes.string,
  materialsToClassModalOpen: PropTypes.bool,
  materialsToClassModalContent: PropTypes.string,
  sendPasswordResetEmailAction: PropTypes.func,
  setRegenerateSignInCardRequestDataAction: PropTypes.func,
  pageLayout: PropTypes.shape({
    ...HubPageLayout.propTypes,
    showPageInnerHero: PropTypes.bool
  })
};

export default withRouter(
  connect(
    (
      state,
      {
        match: {
          params: { classroomId = '', personId = '' }
        }
      }
    ) => {
      const {
        identity: { currentOrganisationId: orgId = '', userId, role: userRole },
        gradebookClassReport: { products = {}, loading: isGradebookLoading },
        search: { profileClasses: { currentClassList = {} } = {} } = {},
        hubPeople: { endSlice = 0, page = 1 } = {},
        hubResourceDownload: { isError, downloadType, assignmentId },
        classrooms: { data: classrooms },
        classroomPage: { selectedStudentIds: selectedStudents = [], selectedTeacherIds: selectedTeachers = [] } = {},
        removeStudentsFromClassroom: { submittedIds: selectedStudentIds = [] } = {},
        classrooms: { data: classes },
        onlineTest: { currentPage },
        people: { data: people },
        hubUi: { materialsToClassModalOpen, materialsToClassModalContent }
      } = state;
      const classUserIds = {
        teacherIdList: [...(classrooms?.[classroomId]?.teacherIdList || [])],
        studentIdList: [...(classrooms?.[classroomId]?.studentIdList || [])]
      };
      const classnameValue = classrooms?.[classroomId]?.name;
      return {
        orgId,
        userId,
        personId,
        userRole,
        people,
        hasProductsInGradebook: !isEmpty(products),
        isGradebookLoading,
        currentClassList,
        classUserIds,
        classnameValue,
        classTitle: (classes[classroomId] || {}).name,
        endSlice,
        peoplePage: page,
        isError,
        downloadType,
        assignmentId,
        selectedStudentIds,
        submittedUsers: [...selectedStudents, ...selectedTeachers],
        canManageAssignments: isAtLeastTeacher(state),
        currentPage,
        materialsToClassModalOpen,
        materialsToClassModalContent
      };
    },
    {
      loadClassDetailsAction: loadClassDetails,
      setActiveClassIdAction: setActiveClassId,
      initialiseSearch: initialiseInstance,
      setPeoplePage: setPageClass,
      hubDownloadRemoveErrorModalAction: hubDownloadRemoveErrorModal,
      openEditClassroomModalAction: openEditClassroomModal,
      setStudentsAction: setUsers,
      setTeachersAction: setTeachersToRemove,
      setMultipleUsersAction: setMultipleUsers,
      clearSelectedUserAction: clearSelectedUser,
      setTeacherSelectedAction: setTeacherSelected,
      setStudentSelectedAction: setStudentSelected,
      toggleClassModalOpenAction: toggleClassModalOpen,
      setContextAction: setContext,
      resetRemoveMaterialPanelAction: resetRemoveMaterialPanel,
      switchToPageAction: switchToPage,
      sendReminderAction: sendReminder,
      getUserDetailsAction: getUserDetails,
      sendPasswordResetEmailAction: resetPasswordRequest,
      setRegenerateSignInCardRequestDataAction: setRegenerateSignInCardRequestData
    }
  )(HubClassLayout)
);
