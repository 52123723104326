import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard } from '@oup/shared-front-end/src/components/Wizard';

import { connect } from 'react-redux';
import SelfRegisteredUserOnboardingChooseRoleWizardFooter from './SelfRegisteredUserOnboardingChooseRoleWizardFooter';

import {
  openJoinClassWithCodeModal,
  showSelfSelectRoleModal,
  closeSelfRegisteredWizard
} from '../../redux/actions/hubUi';

import SelfRegisteredUserOnboardingWizardWelcome from './SelfRegisteredUserOnboardingWizardWelcome/SelfRegisteredUserOnboardingWizardWelcome.js';
import SelfRegisteredUserOnboardingWizardChooseRole from './SelfRegisteredUserOnboardingWizardChooseRole/SelfRegisteredUserOnboardingWizardChooseRole.js';
import SelfRegisteredUserOnboardingWizardChooseOrgType from './SelfRegisteredUserOnboardingWizardChooseOrgType/SelfRegisteredUserOnboardingWizardChooseOrgType.js';
import SelfRegisteredUserOnboardingChooseRoleWizardHeader from './SelfRegisteredUserOnboardingChooseRoleWizardHeader.js';
import SelfRegisteredUserWhatToDoNextModal from './SelfRegisteredUserWhatToDoNextModal/SelfRegisteredUserWhatToDoNextModal';
import SelfRegisteredUserEnterACodeModal from './SelfRegisteredUserEnterACodeModal/SelfRegisteredUserEnterACodeModal.js';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import localStorageWithCache from '../../utils/localStorageWithCache.js';

function SelfRegisteredUserOnboardingChooseRoleWizard({
  isOpen,
  firstName,
  selectRole,
  selfSelectRoleModalOpen,
  getStaffSecondWizardTypeChoice,
  openJoinClassWithCodeModalAction,
  showSelfSelectRoleModalAction,
  closeSelfRegisteredWizardAction
}) {
  const [isOpenWhatToDoNextModal, setIsOpenWhatToDoNextModal] = useState(false);
  const [isEnterACodeModalOpen, setIsEnterACodeModalOpen] = useState(false);
  const { getKey } = localStorageWithCache;
  const { role: selfSelectedRole } = JSON.parse(getKey('selectedRoleData')) || '';

  const enterACodeModalHandleNext = () => {
    setIsEnterACodeModalOpen(false);
    showSelfSelectRoleModalAction(false);
    openJoinClassWithCodeModalAction();
    closeSelfRegisteredWizardAction();
  };

  useEffect(() => {
    if (selfSelectRoleModalOpen) setIsOpenWhatToDoNextModal(selfSelectRoleModalOpen);
  }, []);

  return (
    <div className={wizardStyles.wizardContainer} data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONTAINER">
      <Wizard
        header={<SelfRegisteredUserOnboardingChooseRoleWizardHeader />}
        footer={
          <SelfRegisteredUserOnboardingChooseRoleWizardFooter setIsOpenWhatToDoNextModal={setIsOpenWhatToDoNextModal} />
        }
      >
        <SelfRegisteredUserOnboardingWizardWelcome isOpen={isOpen} firstName={firstName} />
        <SelfRegisteredUserOnboardingWizardChooseRole
          isOpen={isOpen}
          selectRole={selectRole}
          setIsEnterACodeModalOpen={setIsEnterACodeModalOpen}
        />
        {selfSelectedRole === HubLayoutConstants.SELF_SELECT_ROLES[1].key ? (
          <SelfRegisteredUserOnboardingWizardChooseOrgType />
        ) : // TODO add here screen 3 for Student. task will be implemented next sprint
        null}
      </Wizard>
      <SelfRegisteredUserWhatToDoNextModal
        isOpen={isOpenWhatToDoNextModal}
        onClose={() => setIsOpenWhatToDoNextModal(false)}
        onNext={selectedOption => {
          if (selectedOption === 'code') enterACodeModalHandleNext();
          else getStaffSecondWizardTypeChoice(selectedOption);
        }}
      />
      <SelfRegisteredUserEnterACodeModal
        isOpen={isEnterACodeModalOpen}
        onClose={() => setIsEnterACodeModalOpen(false)}
        selectedRole={selectRole}
        onNext={enterACodeModalHandleNext}
      />
    </div>
  );
}

SelfRegisteredUserOnboardingChooseRoleWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  getStaffSecondWizardTypeChoice: PropTypes.func.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
})(SelfRegisteredUserOnboardingChooseRoleWizard);
