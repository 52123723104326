import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import LockIcon from '@oup/shared-front-end/src/svg/oup/icon-close-24.svg';
import copy from 'copy-to-clipboard';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer';
import Toggle from '@oup/shared-front-end/src/components/Toggle';
import TextInputWithButton from '@oup/shared-front-end/src/components/TextInputWithButton/TextInputWithButton';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';

import PopoutActionFooter from '../../../../../components/PopoutActionFooter/PopoutActionFooter';
import SVGIcon, { GLYPHS } from '../../../../../components/SVGIcon/SVGIcon';
import {
  closeEditJoiningCodePanel,
  submitEditJoiningCodeRequest,
  refreshPlacementTestList
} from '../../../../../redux/actions/placementTests';
import PanelHeading from '../../../../../components/PanelHeading/PanelHeading';
import PlacesRemaningInSession from './components/PlacesRemaningInSession';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

import styles from './ManageJoiningCode.scss';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';

import { HubIllustrationAltText, HubIllustrationConstants } from '../../../../../globals/hubConstants';

function ManageJoiningCode({
  customOrgId,
  joiningCode,
  closeEditJoiningCodePanelAction,
  submitEditJoiningCodeAction,
  loading,
  localizedContent: { manageJoiningCode: content, hubGlossary: hubContent },
  codeDetails,
  joiningCodeUpdated,
  handleClose,
  productId,
  refreshPlacementTestListAction
}) {
  const { credits, allocatedCredits } = codeDetails;
  const classCode = `${customOrgId}-${joiningCode}`;

  const [enabled, setEnabled] = useState(true);
  const [valid, setIsValid] = useState(false);
  const [code, setCode] = useState(classCode);
  const [places, setPlaces] = useState(0);
  const [error, setError] = useState(false);
  const [differenceFromMax, setDifferenceFromMax] = useState();

  const setIsValidFunction = param => setIsValid(param);
  const setCodeFunction = param => setCode(param);
  const setPlacesFunction = param => setPlaces(param);
  const setErrorFunction = param => setError(param);

  const onToggle = () => {
    setEnabled(prev => !prev);
  };
  const handleCodeChange = () => {
    handleClose();
    refreshPlacementTestListAction();
  };

  const ToggleComponent = useCallback(() => <Toggle toggle={onToggle} icon={LockIcon} toggled={enabled} />, [enabled]);
  const { orgId, codeId } = codeDetails;

  useEffect(() => {
    if (code !== classCode) {
      setIsValidFunction('warning');
    } else {
      setIsValidFunction('');
    }
  }, [code]);

  if (loading) {
    return (
      <LoadingSpinner
        text={
          <div className={styles.loadingText}>
            <span className={styles.pleaseWait}>{content.pleaseWait}</span>
            <span className={styles.updatingSession}>{content.updatingSession}</span>
          </div>
        }
      />
    );
  }

  if (joiningCodeUpdated) {
    return (
      <div className={styles.updateSuccess}>
        <div className={styles.imageContainerSuccess}>
          <img src={HubIllustrationConstants.SUCCESS} alt={HubIllustrationAltText.SUCCESS} />
          <h2>{content.changesSaved}</h2>
        </div>
        <div className={styles.doneButtoneContainer}>
          <PopoutActionFooter primaryButtonText={hubContent.done_button} primaryButtonAction={handleCodeChange} />
        </div>
      </div>
    );
  }

  const data = {
    code: code.split('-').at(1),
    productId,
    credits: {
      newAllocatedCredits: places,
      credits,
      allocatedCredits
    }
  };

  return (
    <div className={styles.customiseToggleContainer}>
      <PanelHeading
        title="Manage joining Code"
        subtitle={`For ${classCode}`}
        customClassName={styles.customClassNameForToggle}
      />
      <div className={styles.manageJoiningCode}>
        <div className={styles.manageJoiningCodeContent}>
          <div className={styles.toggleButtonAndText}>
            <ToggleComponent />
            <span className={styles.allowStudents}>{content.allowStudents}</span>
          </div>
          <div className={styles.textInputContent}>
            <span className={styles.joiningCode}>{content.joiningCode}</span>
            <TextInputWithButton
              buttonDisabled={!enabled}
              disabled={!enabled}
              id="copyJoiningCode"
              placeholder=""
              buttonText="Copy"
              buttonIcon={<SVGIcon glyph={GLYPHS.ICON_COPY} />}
              icon={<SVGIcon glyph={GLYPHS.ICON_COPY} />}
              onClick={copy}
              value={code}
              isValid={valid}
              onChange={e => {
                setCodeFunction(e);
              }}
            />
            {valid === 'warning' && <span className={styles.ifCodeIsChanged}> {content.dontChangeCode}</span>}
          </div>
          <PlacesRemaningInSession
            enabled={enabled}
            places={places}
            error={error}
            differenceFromMax={differenceFromMax}
            setPlaces={setPlacesFunction}
            setError={setErrorFunction}
            setDifferenceFromMax={setDifferenceFromMax}
            credits={credits}
            allocatedCredits={allocatedCredits}
            classCode={classCode}
            setCodeFunction={setCodeFunction}
          />
          <div className={styles.copyToStudents}>
            <span>
              {content.copyAndShare} <strong> {content.englishHubLink}</strong>
            </span>
            <span>
              {' '}
              {content.andChoosing} <strong>`{content.enterACode}`</strong>
            </span>
          </div>

          <div className={styles.learnMoreAboutCodes}>
            <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
              {content.learnAboutCodes}
            </TextLink>
          </div>
          <div className={styles.footerContainer}>
            <Footer
              secondaryButtonAction={closeEditJoiningCodePanelAction}
              primaryButtonAction={() => {
                submitEditJoiningCodeAction({ orgId, joiningCodeId: codeId, data });
              }}
              secondaryButtonLabel="Cancel"
              primaryButtonLabel="Save"
              primaryButtonAttributes={{
                disabled: !enabled || error
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
ManageJoiningCode.propTypes = {
  customOrgId: PropTypes.string,
  joiningCode: PropTypes.string,
  localizedContent: PropTypes.object,
  closeEditJoiningCodePanelAction: PropTypes.func,
  submitEditJoiningCodeAction: PropTypes.func,
  loading: PropTypes.bool,
  codeDetails: PropTypes.object,
  joiningCodeUpdated: PropTypes.bool,
  handleClose: PropTypes.func,
  productId: PropTypes.string,
  refreshPlacementTestListAction: PropTypes.func
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests', 'manageJoiningCode'),
  connect(
    ({
      placementTestSessionCreate,
      organisations,
      identity,
      loadPlacementTestsReducer: { loading, codeDetails, joiningCodeUpdated }
    }) => ({
      productId: placementTestSessionCreate.placementTest.productId,
      customOrgId: organisations?.data[identity.currentOrganisationId]?.customId,
      joiningCode: placementTestSessionCreate.joiningCode,
      loading,
      codeDetails,
      joiningCodeUpdated
    }),
    {
      closeEditJoiningCodePanelAction: closeEditJoiningCodePanel,
      submitEditJoiningCodeAction: submitEditJoiningCodeRequest,
      refreshPlacementTestListAction: refreshPlacementTestList
    }
  )
)(ManageJoiningCode);
