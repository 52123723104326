import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList/RadioButtonList';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink/TextLink';
import { ACCESS_CODE_TYPES } from '@oup/shared-node-browser/constants';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ICON_CLASS, ICON_COURSE, ICON_ASSESSMENT } from '@oup/shared-front-end/src/svg/oup/index';
import HubModalLayout from '../../../components/HubModalLayout/HubModalLayout';
import styles from './EnterCodeModal.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import LegacyButton, { buttonTypes } from '../../../components/Button/Button';

function EnterCodeModal({
  isOpen,
  onClose,
  onNext,
  localizedContent: { enterCode: content, hubGlossary: hubContent }
}) {
  const modalRef = useRef(null);
  const selectionInitialState = '';
  const [selectedOption, setSelectedOption] = useState(selectionInitialState);
  const isDisabledNextBtn = !selectedOption;

  const handleOptionSelected = e => {
    setSelectedOption(e.target.value);
  };

  const handleNextBtn = () => {
    onNext(selectedOption);
  };

  const handleClose = () => {
    setSelectedOption(selectionInitialState);
    onClose();
  };

  return (
    isOpen && (
      <div className={`${styles.outerEnterCodeModal} ${isOpen ? styles.open : ''}`}>
        <HubModalLayout
          isOpen={isOpen}
          closeAction={onClose}
          focusOnFirstField={false}
          ariaLabel={content.enter_code_modal_title}
        >
          <div
            data-testid="ENTER_CODE_MODAL"
            className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
            aria-hidden={!isOpen}
            ref={modalRef}
          >
            <div className={styles.modalHeader}>
              <LegacyButton type={buttonTypes.CLOSE_WHITE} iconOnly onClick={handleClose} />
              <h2 className={styles.title}>{content.enter_code_modal_title}</h2>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.options}>
                <RadioButtonList
                  type="outline"
                  name="enter-code-options"
                  legend="legend name test 1"
                  noShadow
                  checkedValue={[selectedOption]}
                  radioGroup={[
                    {
                      id: `enter-product-code-radio`,
                      label: content.enter_product_code_title,
                      subLabel: content.enter_product_code_subtitle,
                      value: ACCESS_CODE_TYPES.PRODUCT,
                      icon: (
                        <div className={styles.icon}>
                          <ICON_COURSE />
                        </div>
                      )
                    },
                    {
                      id: `enter-class-code-radio`,
                      label: content.enter_class_code_title,
                      subLabel: content.enter_class_code_subtitle,
                      value: ACCESS_CODE_TYPES.CLASS,
                      icon: (
                        <div className={styles.icon}>
                          <ICON_CLASS />
                        </div>
                      )
                    },
                    {
                      id: `enter-placement-test-code-radio`,
                      label: content.enter_placement_test_code_title,
                      subLabel: content.enter_placement_test_code_subtitle,
                      value: ACCESS_CODE_TYPES.PLACEMENT_TEST,
                      icon: (
                        <div className={styles.icon}>
                          <ICON_ASSESSMENT />
                        </div>
                      )
                    }
                  ]}
                  onChange={handleOptionSelected}
                />
              </div>
            </div>
            <hr className={styles.hr} />
            <div className={styles.modalFooter}>
              <TextLink
                dataTestId="ENTER_CODE_MODAL_CANCEL_BUTTON"
                onClick={e => {
                  e.preventDefault();
                  handleClose();
                }}
                ariaAttributes={{
                  label: hubContent.cancel
                }}
              >
                {hubContent.cancel}
              </TextLink>
              <Button
                dataTestId="ENTER_CODE_MODAL_CONFIRM_BUTTON"
                text={hubContent.next}
                onClick={handleNextBtn}
                disabled={isDisabledNextBtn}
                ariaAttributes={{
                  label: hubContent.next
                }}
              />
            </div>
          </div>
        </HubModalLayout>
      </div>
    )
  );
}

EnterCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('enterCode', 'hubGlossary'), connect(null, {}))(EnterCodeModal);
