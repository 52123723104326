import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';
import { ICON_STUDENT_CHOOSE_ROLE_24, ICON_TEACHER_CHOOSE_ROLE_24 } from '@oup/shared-front-end/src/svg/oup';

import roleSelectStyles from './SelfRegisteredUserOnboardingWizardChooseRole.scss';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardChooseRole({
  localizedContent: { selfRegisteredUserOnboardingWizard: content },
  selectRole,
  setIsEnterACodeModalOpen
}) {
  const { handleStep, setIsPrimaryButtonDisabled } = useWizard();

  const [selectedOption, setSelectedOption] = useState('');

  const handleRoleSelect = roleKey => {
    selectRole(roleKey, true);
  };

  useEffect(() => {
    setIsPrimaryButtonDisabled(!selectedOption);
  }, [selectedOption]);

  handleStep(() => {
    if (selectedOption === HubLayoutConstants.SELF_SELECT_ROLES[0].key) {
      setIsEnterACodeModalOpen(true);
    }
  });

  return (
    <div
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CHOOSE_ROLE_CONTAINER"
      className={roleSelectStyles.chooseRoleStep}
    >
      <WizardStep
        titleText={content.self_registered_user_onboarding_wizard_choose_role}
        subtitleText={content.self_registered_user_onboarding_wizard_role_choose_step_subtitle}
      >
        <RadioButtonList
          isColumn={false}
          type="outline"
          name="choose-role"
          legend="choose role"
          checkedValue={[selectedOption]}
          radioGroup={[
            {
              id: HubLayoutConstants.SELF_SELECT_ROLES[0].key,
              label: HubLayoutConstants.SELF_SELECT_ROLES[0].title,
              value: HubLayoutConstants.SELF_SELECT_ROLES[0].key,
              icon: <ICON_STUDENT_CHOOSE_ROLE_24 />
            },
            {
              id: HubLayoutConstants.SELF_SELECT_ROLES[1].key,
              label: HubLayoutConstants.SELF_SELECT_ROLES[1].title,
              value: HubLayoutConstants.SELF_SELECT_ROLES[1].key,
              icon: <ICON_TEACHER_CHOOSE_ROLE_24 />
            }
          ]}
          onChange={e => {
            setSelectedOption(e.target.value);
            handleRoleSelect(e.target.value);
          }}
        />
      </WizardStep>
    </div>
  );
}

SelfRegisteredUserOnboardingWizardChooseRole.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  selectRole: PropTypes.func.isRequired,
  setIsEnterACodeModalOpen: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  SelfRegisteredUserOnboardingWizardChooseRole
);
