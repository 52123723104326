import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PropTypes from 'prop-types';
import styles from '../ManageJoiningCode.scss';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import { editErrorResponseField } from '../../../../../../redux/actions/placementTests';
import StudentsCounting from '../../../../../../components/PlacementTestOnBoardingWizard/AddStudents/StudentsCounting/StudentsCounting';

function PlacesRemaningInSession({
  localizedContent: { manageJoiningCode: content },
  enabled,
  places,
  error,
  differenceFromMax,
  setPlaces,
  setError,
  setDifferenceFromMax,
  errorResponse,
  editErrorResponseFieldAction,
  credits,
  allocatedCredits,
  classCode,
  setCodeFunction
}) {
  useEffect(() => {
    setPlaces(allocatedCredits);
  }, []);

  useEffect(() => {
    if (places > credits + allocatedCredits) {
      setDifferenceFromMax(places - (credits + allocatedCredits));
      setError(true);
    } else {
      setError(false);
    }
    if (!enabled) {
      setError(false);
      setPlaces(allocatedCredits);
      editErrorResponseFieldAction('');
      setCodeFunction(classCode);
    }
  }, [places, enabled]);

  return (
    <div className={styles.placesRemaning}>
      <div className={styles.placesRemaning}>
        <span className={styles.placesRemaningText}>{content.placesRemaningInSession}</span>
        <StudentsCounting
          disabled={!enabled}
          value={places}
          onDecrease={() => {
            setPlaces(prev => (prev === 0 ? 0 : prev - 1));
          }}
          onIncrease={() => {
            setPlaces(prev => prev + 1);
          }}
          hasError={error}
          errorMessage={
            <span className={styles.errorMessage}>
              {content.notEnoughLicences} {differenceFromMax} {content.aditionalLicencesNeeded} ({credits}{' '}
              {content.licencesRemaning}).
            </span>
          }
          disabledClass={styles.disabledColor}
        />
      </div>

      {error && (
        <div className={styles.errorMessageContainer}>
          <span className={styles.buyMoreLicences}>{content.buyMoreLicences}</span>
        </div>
      )}
      {errorResponse && (
        <div className={styles.errorMessageContainer}>
          {' '}
          <span className={styles.errorMessage}>{errorResponse}</span>
        </div>
      )}
    </div>
  );
}

PlacesRemaningInSession.propTypes = {
  localizedContent: PropTypes.object,

  enabled: PropTypes.bool,
  places: PropTypes.number,
  error: PropTypes.bool,
  differenceFromMax: PropTypes.number,
  setPlaces: PropTypes.func,
  setError: PropTypes.func,
  setDifferenceFromMax: PropTypes.func,
  errorResponse: PropTypes.string,
  editErrorResponseFieldAction: PropTypes.func,
  credits: PropTypes.number,
  allocatedCredits: PropTypes.number,
  classCode: PropTypes.string,
  setCodeFunction: PropTypes.func
};

export default compose(
  withLocalizedContent('manageJoiningCode'),
  connect(
    ({ loadPlacementTestsReducer: { codeDetails, errorResponse } }) => ({
      codeDetails,
      errorResponse
    }),
    {
      editErrorResponseFieldAction: editErrorResponseField
    }
  )
)(PlacesRemaningInSession);
