import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Utils
import Button from '@oup/shared-front-end/src/components/Button';
import breakpoints from '../../../globals/breakpoints';
import getSideNavItems from '../Services/getNavigationItems';
// Components
import Link from '../../../components/Link/Link';
import Thumbnail, { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import SecondaryLevelContainer from './SecondaryLevelContainer';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
// Styles
import styles from './HubSideNavbar.scss';

class HubSideNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      toggled: ''
    };
  }

  componentDidUpdate(prevProps) {
    const {
      props: { isOpen }
    } = this;

    // If side nav has been closed from the hamburger button then collapse the secondary navigation
    if (prevProps.isOpen !== isOpen && isOpen === false) {
      this.collapseNavItems();
    }
  }

  handleHoverOn = () => this.setState({ hovered: true });

  handleHoverOff = () => this.setState({ hovered: false });

  // Collapse nav items
  collapseNavItems = () => this.setState({ toggled: '' });

  handleMouseEnter = () => this.handleHoverOn();

  handleMouseLeave = () => {
    const {
      props: { isOpen }
    } = this;

    this.handleHoverOff();

    if (!isOpen) this.collapseNavItems();
  };

  handleSelectNavItem = (event, { canToggle, path }) => {
    const {
      props: { closeNavigation, breakpoint }
    } = this;

    // Toggle selected nav item
    if (canToggle) this.setState(state => ({ toggled: state.toggled === path ? '' : path }));

    if (!canToggle) {
      this.handleHoverOff();
      this.collapseNavItems();
    }

    if (breakpoint !== breakpoints.LG && !canToggle) closeNavigation();
  };

  handleSelectSecondaryLevel = () => {
    const {
      props: { breakpoint, closeNavigation },
      state: { hovered }
    } = this;
    // Always handleHoverOff to avoid other bugs
    if (hovered) this.handleHoverOff();

    // Always collapse nav items on select secondary level
    this.collapseNavItems();

    // If breakpoint not LG always close navigation, <1180 px
    if (breakpoint !== breakpoints.LG) closeNavigation();
  };

  render() {
    const {
      props: {
        isOpen,
        pathname,
        hubContent,
        userRole,
        organization,
        openRedeemModal,
        openEnterCodeModal,
        openCreateClassModal,
        openJoinClassWithCodeModal,
        openOnboardingWizard,
        currentOrganisationLti
      },
      state: { toggled, hovered }
    } = this;

    const navItemsActions = {
      openRedeemModal,
      openEnterCodeModal,
      openCreateClassModal,
      openJoinClassWithCodeModal,
      openOnboardingWizard
    };

    const sideNavItems = getSideNavItems(hubContent, userRole, organization, navItemsActions, currentOrganisationLti);

    return (
      <nav
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={classnames(styles.sideNav, { [styles.open]: isOpen }, { [styles.hovered]: hovered })}
        aria-label="Secondary navigation"
      >
        <ul>
          {sideNavItems.map(item => {
            if (item.disabled) return null;
            if (item.isButton) {
              return (
                <li key={item.id} className={styles.enterCodeButton}>
                  <Button {...item} icon={{ component: <SVGIcon glyph={item.icon} /> }} />
                </li>
              );
            }
            if (!item.canToggle && item.showTab) {
              return (
                <li
                  key={item.id}
                  onMouseEnter={this.handleMouseEnter}
                  className={item.tasksNumber ? styles.taskBadgeDisplayed : ''}
                >
                  <Link
                    onClick={event => this.handleSelectNavItem(event, item)}
                    id={item.id}
                    className={classnames([styles.sideNavLink], {
                      [styles.sideNavItemSelected]: pathname.startsWith(item.path)
                    })}
                    to={item.linkTo}
                    tabIndex={item.tabindex}
                    dataTestId={item.testHook}
                    aria-label={item.ariaLabel}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        this.handleSelectNavItem(event, item);
                      }
                    }}
                  >
                    <div className={styles.thumbnailContainer}>
                      <SVGIcon glyph={item.icon} />
                    </div>

                    <div
                      className={
                        item.navSubTitle
                          ? `${styles.itemDetailsContainer} ${styles.WithSubtitle}`
                          : styles.itemDetailsContainer
                      }
                    >
                      <div className={styles.itemTitle}>{item.navText}</div>
                      {item.navSubTitle && <small className={styles.itemSubTitle}>{item.navSubTitle}</small>}
                    </div>
                    {item.tasksNumber ? <div className={styles.tasksBadge}>{item.tasksNumber}</div> : null}
                  </Link>
                </li>
              );
            }

            if (item.canToggle && item.showTab) {
              return (
                <li key={item.id} onMouseEnter={this.handleMouseEnter}>
                  <div
                    role="button"
                    onClick={event => this.handleSelectNavItem(event, item)}
                    id={item.id}
                    className={classnames([styles.sideNavLink], {
                      [styles.sideNavItemSelected]: pathname.startsWith(item.path)
                    })}
                    tabIndex={item.tabindex}
                    data-testid={item.testHook}
                    aria-label={`${item.ariaLabel} ${toggled === item.path ? 'expanded' : 'collapsed'}`}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        this.handleSelectNavItem(event, item);
                      }
                    }}
                  >
                    <div className={styles.thumbnailContainer}>
                      <SVGIcon glyph={item.icon} />
                    </div>

                    <div
                      className={
                        item.navSubTitle
                          ? `${styles.itemDetailsContainer} ${styles.WithSubtitle}`
                          : styles.itemDetailsContainer
                      }
                    >
                      <div className={styles.itemTitle}>{item.navText}</div>
                      {item.navSubTitle && <small className={styles.itemSubTitle}>{item.navSubTitle}</small>}
                      <div className={classnames(styles.carat, { [styles.caratOpen]: toggled === item.path })}>
                        <Thumbnail glyph={GLYPHS.ICON_CARAT} size={thumbnailSizes.SMALL} />
                      </div>
                    </div>
                  </div>

                  <SecondaryLevelContainer
                    isSelected={toggled === item.path}
                    hubContent={hubContent}
                    handleSelectSecondaryLevel={this.handleSelectSecondaryLevel}
                    toggledItems={item.toggledItems}
                    path={item.path}
                    userRole={userRole}
                  />
                </li>
              );
            }

            return null;
          })}
        </ul>
      </nav>
    );
  }
}

HubSideNavbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired,
  closeNavigation: PropTypes.func.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  openEnterCodeModal: PropTypes.func.isRequired,
  openCreateClassModal: PropTypes.func,
  openJoinClassWithCodeModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  organization: PropTypes.object,
  openOnboardingWizard: PropTypes.func,
  currentOrganisationLti: PropTypes.bool
};

export default HubSideNavbar;
