import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import TextLink from '@oup/shared-front-end/src/components/TextLink';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';
import { ICON_ASSESSMENT, ICON_CLASS } from '@oup/shared-front-end/src/svg/oup';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import styles from './SelfRegisteredUserOnboardingWizardChooseOrgType.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { closeSelfRegisteredWizard } from '../../../redux/actions/hubUi';

function SelfRegisteredUserOnboardingWizardChooseOrgType({
  localizedContent: { selfRegisteredUserOnboardingWizard: content },
  closeSelfRegisteredWizardAction
}) {
  const [selectedOption, setSelectedOption] = useState('');

  const { setIsPrimaryButtonDisabled } = useWizard();

  useEffect(() => {
    setIsPrimaryButtonDisabled(!selectedOption);
  }, [selectedOption]);

  return (
    <div
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONFIRMATION_CONTAINER"
      className={styles.chooseOrgTypeStep}
    >
      <WizardStep titleText={content.self_registered_user_onboarding_wizard_choose_org_type_title}>
        <div className={styles.chooseOrgTypeContainer}>
          <RadioButtonList
            isColumn={false}
            type="outline"
            name="choose-type-of-org"
            legend="choose type of org"
            checkedValue={[selectedOption]}
            radioGroup={[
              {
                id: 'teachingAndLearning',
                label: content.self_registered_user_onboarding_wizard_choose_org_type_teaching_and_learning_label,
                subLabel: content.self_registered_user_onboarding_wizard_choose_org_type_teaching_and_learning_sublabel,
                value: 'teachingAndLearning',
                icon: <ICON_CLASS />
              },
              {
                id: 'assessment',
                label: content.self_registered_user_onboarding_wizard_choose_org_type_assessment_label,
                subLabel: content.self_registered_user_onboarding_wizard_choose_org_type_assessment_sublabel,
                value: 'assessment',
                icon: <ICON_ASSESSMENT />
              }
            ]}
            onChange={e => setSelectedOption(e.target.value)}
          />
          {selectedOption === 'teachingAndLearning' && (
            <div className={styles.chooseOrgTypeDescription}>
              <div className={styles.images}>
                <div>
                  <img src="/media/hub/teaching-and-learning.jpg" alt="Teachung and learning" />
                </div>
              </div>
              <div className={styles.text}>
                {content.self_registered_user_onboarding_wizard_choose_org_type_teaching_and_learning_description}
                <div className={styles.link}>
                  <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
                    {content.staff_role_chooses_classes_and_courses_learn_more_link_text}
                  </TextLink>
                </div>
              </div>
            </div>
          )}
          {selectedOption === 'assessment' && (
            <div className={styles.chooseOrgTypeDescription}>
              <div className={styles.images}>
                <div>
                  <img src="/media/products/opt/opt.png" alt="Oxford Placement Test" />
                </div>
                <div>
                  <img src="/media/products/opt/optyl.png" alt="Oxford Placement Test For Young Learners" />
                </div>
              </div>
              <div className={styles.text}>
                {content.self_registered_user_onboarding_wizard_choose_org_type_assessment_description}
                <div className={styles.link}>
                  <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
                    {content.self_registered_user_onboarding_wizard_buy_licences_for_opt}
                  </TextLink>
                </div>
                <div className={styles.link}>
                  <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
                    {content.self_registered_user_onboarding_wizard_learn_more_about_opt}
                  </TextLink>
                </div>
              </div>
            </div>
          )}
          <TextLink
            component={RouterLink}
            onClick={e => {
              e.preventDefault();
              closeSelfRegisteredWizardAction();
            }}
          >
            {content.self_registered_user_what_to_do_next_continue_to_hub}
          </TextLink>
        </div>
      </WizardStep>
    </div>
  );
}

SelfRegisteredUserOnboardingWizardChooseOrgType.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'),
  connect(null, {
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
  })
)(SelfRegisteredUserOnboardingWizardChooseOrgType);
