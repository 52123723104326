import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import withLocalizedContent from '../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingChooseRoleWizardFooter({
  localizedContent: { hubGlossary: hubContent },
  setIsOpenWhatToDoNextModal
}) {
  const { nextStep, isPrimaryButtonDisabled, isLastStep } = useWizard();

  const handleStep = () => {
    if (isLastStep) {
      setIsOpenWhatToDoNextModal(true);
    } else {
      nextStep();
    }
  };

  const setPrimaryButtonLabel = () => hubContent.next;

  return (
    <Footer
      primaryButtonAction={handleStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: isPrimaryButtonDisabled,
        dataAttributes: {
          testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

SelfRegisteredUserOnboardingChooseRoleWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setIsOpenWhatToDoNextModal: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('hubGlossary'))(SelfRegisteredUserOnboardingChooseRoleWizardFooter);
