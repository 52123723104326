import React, { useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Redux
import {
  regenerateSignInCardRequest,
  resetRegenerateSignInCardState
} from '../redux/actions/regenerateSupervisedUserSignInCard';
// Utils
import withLocalizedContent from '../language/withLocalizedContent';
// Components
import RegenerateSignInCardProcessing from '../components/RegenerateSupervisedUserSignInCard/RegenerateSignInCardProcessing';
import RegenerateSignInCardFailure from '../components/RegenerateSupervisedUserSignInCard/RegenerateSignInCardFailure';
import RegenerateSignInCardSuccess from '../components/RegenerateSupervisedUserSignInCard/RegenerateSignInCardSuccess';
import { SidePanel } from '../components';

function RegenerateSupervisedUserSignInCardPanel({
  isOpen,
  onClose,
  orgId,
  orgFriendlyId,
  regenerateSignInCardRequestAction,
  resetRegenerateSignInCardStateAction,
  signInCardUrl,
  userId,
  userName,
  firstName,
  lastName,
  isLoading,
  success,
  localizedContent: { hubGlossary: hubContent }
}) {
  const regenerateSignIncard = () => {
    regenerateSignInCardRequestAction({
      orgId,
      userId,
      userNameWithoutOrg: userName,
      firstName,
      lastName,
      orgFriendlyId
    });
  };

  useEffect(() => {
    if (isOpen && userId) {
      regenerateSignIncard();
    }
  }, [isOpen]);

  const handleClosePanel = () => {
    resetRegenerateSignInCardStateAction();
    onClose();
  };

  const renderContent = () => {
    switch (true) {
      case isLoading:
        return <RegenerateSignInCardProcessing content={hubContent} hideCloseButton />;
      case success && !!signInCardUrl:
        return (
          <RegenerateSignInCardSuccess signInCardUrl={signInCardUrl} content={hubContent} onClose={handleClosePanel} />
        );
      default:
        return <RegenerateSignInCardFailure content={hubContent} regenerateSignInCard={regenerateSignIncard} />;
    }
  };
  return (
    <SidePanel
      id="RegenerateSupervisedUserSignInCard"
      ariaLabel={hubContent.renegerate_sign_in_card_aria_label}
      isOpen={isOpen}
      onClose={handleClosePanel}
    >
      {renderContent()}
    </SidePanel>
  );
}

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    ({ identity = {}, organisations = {}, regenerateSupervisedUserSignInCard = {} }) => ({
      orgId: identity.currentOrganisationId,
      orgFriendlyId: organisations.data[identity.currentOrganisationId]?.customId,
      userId: regenerateSupervisedUserSignInCard.userId,
      userName: regenerateSupervisedUserSignInCard.userName,
      firstName: regenerateSupervisedUserSignInCard.firstName,
      lastName: regenerateSupervisedUserSignInCard.lastName,
      signInCardUrl: regenerateSupervisedUserSignInCard.signInCardUrl,
      isLoading: regenerateSupervisedUserSignInCard.isLoading,
      success: regenerateSupervisedUserSignInCard.success
    }),
    {
      regenerateSignInCardRequestAction: regenerateSignInCardRequest,
      resetRegenerateSignInCardStateAction: resetRegenerateSignInCardState
    }
  )
)(RegenerateSupervisedUserSignInCardPanel);

RegenerateSupervisedUserSignInCardPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgFriendlyId: PropTypes.string.isRequired,
  regenerateSignInCardRequestAction: PropTypes.func.isRequired,
  resetRegenerateSignInCardStateAction: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signInCardUrl: PropTypes.string,
  success: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};
