import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@oup/shared-front-end/src/components/Link';
import isExternalLink from '@oup/shared-front-end/src/utils/isExternalLink';

import styles from './LastOpenedItem.scss';

function LastOpenedItem({ title = '', subtitle = '', to = '', imageOrIcon }) {
  return (
    <Link className={styles.container} to={to} component={isExternalLink(to) ? 'a' : RouterLink}>
      <div className={styles.childContainer}>{imageOrIcon}</div>
      <div>
        <div className={classnames(styles.title, { [styles.noSubtitle]: !subtitle })}>{title}</div>
        {subtitle && (
          <div className={styles.subtitleContainer}>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        )}
      </div>
    </Link>
  );
}

LastOpenedItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  to: PropTypes.string.isRequired,
  imageOrIcon: PropTypes.node
};

export default LastOpenedItem;
